<template>
  <AppLayout>
    <ViewWrapper
      title="Reports"
      :tab="tab"
      :tabs="_tabs"
      :breadcrumbs="breadcrumbs"
      @quickHelp="showQuickHelp"
    >
      <!-- header -->

      <template #header-actions>
        <BaseButton
          v-if="showAddReport"
          icon="eva-plus"
          label="Create Reports"
          class="q-ml-md"
          @click="addReports"
        />
      </template>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->

      <!-- child routes -->

      <router-view :key="$route.fullPath" />

      <!-- ... -->
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";

export default {
  name: "Reports",

  components: { AppLayout, ViewWrapper, QuickHelp },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "Reports",
          route: "reports-overview",
        },
        {
          id: this.$nano.id(),
          label: "",
          route: "",
        },
      ],
      tabs: [
        {
          id: this.$nano.id(),
          label: "overview",
          icon: "mdi-view-dashboard-outline",
          route: "reports-overview",
          access: false,
          accessLabel: "Overview",
        },
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "reports-browse",
          access: false,
          accessLabel: "Manage Reports",
        },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "reports-trash",
          access: false,
          accessLabel: "Manage Reports",
        },
      ],
      tab: "reports-browse",
      quickHelp: false,
    };
  },

  computed: {
    showAddReport() {
      if (this.$store.state.profileMenus.length)
        return this.$store.state.profileMenus.find((row) => row.reports).Menu[
          "Manage Reports"
        ];

      return false;
    },

    _tabs() {
      if (this.$store.state.profileMenus.length) {
        this.tabs.forEach((tab) => {
          if (!tab.access) {
            tab.access = this.$store.state.profileMenus.find(
              (row) => row.reports
            )?.Menu[tab.accessLabel];
          }
        });
      }
      return this.tabs.filter((tab) => tab.access);
    },
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        const index = this.breadcrumbs.length - 1;
        this.breadcrumbs[index].label = this.$route.meta.breadcrumb;
      },
    },
  },

  methods: {
    showQuickHelp() {
      this.quickHelp = true;
    },
    addReports() {
      this.$router.push({
        name: "reports-builder",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
